import { FC } from 'react'
import { SvgProps } from './svgProps'

const GoogleIcon: FC<SvgProps> = ({ width, height }) => (
  <svg
    width={width || 21}
    height={height || 21}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.37103 3.00393L6.16523 5.80362C6.72382 5.22338 7.39271 4.7611 8.13247 4.44404C8.87223 4.12698 9.66787 3.96157 10.4725 3.95756C11.277 3.95355 12.0743 4.11102 12.8171 4.42069C13.56 4.73035 14.2335 5.18594 14.7978 5.76058L17.5894 2.95961C16.6582 2.01824 15.5494 1.2718 14.3276 0.763717C13.1058 0.255632 11.7954 -0.00395872 10.4725 4.56301e-05C9.14968 0.00404998 7.84084 0.271569 6.62212 0.787042C5.40341 1.30252 4.29916 2.05565 3.37361 3.00264L3.37103 3.00393Z"
      fill="#F0432C"
    />
    <path
      d="M3.40754 17.0544L6.19917 14.2573C5.64074 13.6935 5.19881 13.0249 4.89868 12.2897C4.59855 11.5545 4.44611 10.7672 4.4501 9.97291C4.45409 9.17861 4.61443 8.3929 4.92193 7.66077C5.22943 6.92865 5.67806 6.2645 6.24211 5.70636L3.44877 2.9071C2.51798 3.83289 1.77845 4.93344 1.27249 6.14582C0.766518 7.35819 0.504035 8.65861 0.500046 9.9727C0.496057 11.2868 0.750641 12.5888 1.24924 13.8042C1.74784 15.0197 2.48067 16.1247 3.40583 17.0561L3.40754 17.0544Z"
      fill="#FFBF00"
    />
    <path d="M12.4752 11.98V12.0213H12.5165L12.4752 11.98Z" fill="#24AA49" />
    <path
      d="M10.479 20C11.7922 20.0017 13.0928 19.7428 14.3058 19.2384C15.5187 18.7339 16.62 17.9939 17.5461 17.0609L14.7523 14.2607C14.1923 14.8252 13.5265 15.2731 12.7931 15.5787C12.0597 15.8844 11.2732 16.0417 10.479 16.0417C9.68466 16.0417 8.89821 15.8844 8.16483 15.5787C7.43145 15.2731 6.76561 14.8252 6.20562 14.2607L3.414 17.0578C4.33957 17.9911 5.44037 18.7316 6.65293 19.2366C7.8655 19.7415 9.16583 20.001 10.479 20Z"
      fill="#24AA49"
    />
    <path
      d="M20.308 8.04218L12.5164 8.05293V12.0214H16.203C15.8979 12.8797 15.4033 13.658 14.7561 14.2982L17.5499 17.0979C18.7196 15.9336 19.5836 14.4974 20.0649 12.9174C20.5461 11.3373 20.6297 9.66245 20.308 8.04218Z"
      fill="#3384FC"
    />
  </svg>
)

export default GoogleIcon
