import { FC } from 'react'
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'
import { Flex } from '@/components/atoms/Grid'

import { IconButtonCapitalized } from '@/components/molecules/iconButtons'
import { useRouter } from 'next/router'
import { Routes } from '@/core/routes'
import { GoogleIconDefault } from '@/components/atoms/icons/GoogleIconDefault'
import { useFacebook } from '@/providers/facebookProvider'

import { useGoogleLogin } from '@react-oauth/google'
import { notifyError } from '@/core/toast'

import { useAuth } from '@/providers/authProvider'

type GoogleResponse = {
  access_token?: string
  token_type?: string
  expires_in?: number
  scope?: string
  authuser?: string
  prompt?: string
}

const LoginSocialButtons: FC = () => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const isLoginPage = router.pathname === Routes.login
  const { getAccessToken } = useFacebook()

  const { loginByFacebook, loginByGoogle } = useAuth()

  const handleFacebookLogin = async () => {
    const accessToken = await getAccessToken()

    if (accessToken) {
      loginByFacebook(accessToken, Routes.home)
    }
  }

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (responseGoogle: GoogleResponse | null) => {
      if (responseGoogle?.access_token) {
        loginByGoogle(responseGoogle?.access_token)
      }
    },
    onError: (error) => {
      const errorMessage = t('Login.loginFailed')
      notifyError(errorMessage)
      localStorage.set('Error_google_login', JSON.stringify(error))
    }
  })

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      width={{ mobile: '100%', tablet: '100%', desktop: 500 }}
    >
      {/* <FacebookButton
        onClick={handleFacebookLogin}
        IconElement={<FacebookCircleIconDefault />}
        title={
          isLoginPage
            ? t('LoginPage.Facebook.title')
            : t('Register.Facebook.title')
        }
        isSocialButton
      /> */}
      <GoogleButton
        onClick={handleGoogleLogin}
        IconElement={<GoogleIconDefault />}
        title={
          isLoginPage ? t('LoginPage.Gmail.title') : t('Register.Gmail.title')
        }
        isSocialButton
      />
    </Flex>
  )
}

export default LoginSocialButtons

const FacebookButton = styled(IconButtonCapitalized)`
  align-items: center;
  height: 54px;
  margin-top: 36px;
  border: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.facebook};
  color: ${(props) => props.theme.colors.background};
  &:hover {
    background-color: ${(props) => props.theme.colors.facebookHover};
  }
`

const GoogleButton = styled(IconButtonCapitalized)`
  align-items: center;
  height: 54px;
  margin-top: 16px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.googleButtonBorder};
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.onBackground};
  &:hover {
    background-color: ${(props) => props.theme.colors.listItemSeparator};
  }
`
